export const USE_QUERY_DEFAULT_OPTIONS = {
    refetchOnWindowFocus: false,
    staleTime: 4 * 60 * 60 * 1000,  // 4 hour
    cacheTime: 4 * 60 * 60 * 1000  // 4 hours
}

export const SHOW_NONPROD_BANNER = 'SHOW_NONPROD_BANNER';
export const ENABLE_TSS_BUSINESS_UNIT_VIEW = 'ENABLE_TSS_BUSINESS_UNIT_VIEW';
export const ENABLE_TSS_ZONE_VIEW = 'ENABLE_TSS_ZONE_VIEW';
export const ENABLE_CUSTOMER_CHAIN_VIEW = 'ENABLE_CUSTOMER_CHAIN_VIEW';
export const ENABLE_CUSTOMER_BRANCH_VIEW = 'ENABLE_CUSTOMER_BRANCH_VIEW';
export const ENABLE_CUSTOMER_BU_VIEW = 'ENABLE_CUSTOMER_BU_VIEW';
export const MKT_SCHEDULE_REFRESH = 'MKT_SCHEDULE_REFRESH';
export const ENABLE_LOCATIONS_BRANCH_VIEW = 'ENABLE_LOCATIONS_BRANCH_VIEW';
export const ENABLE_MERCHANDISE_BRANCH_VIEW = 'ENABLE_MERCHANDISE_BRANCH_VIEW';
export const ENABLE_MERCHANDISE_BUSINESS_UNIT_VIEW = 'ENABLE_MERCHANDISE_BUSINESS_UNIT_VIEW';

export const DEFAULT_SETTINGS = {
    [SHOW_NONPROD_BANNER]: false,
    [ENABLE_TSS_BUSINESS_UNIT_VIEW]: false,
    [ENABLE_TSS_ZONE_VIEW]: false,    
    [ENABLE_LOCATIONS_BRANCH_VIEW]: false,
    [ENABLE_CUSTOMER_CHAIN_VIEW]: false,
    [ENABLE_CUSTOMER_BRANCH_VIEW]: false,
    [ENABLE_CUSTOMER_BU_VIEW]: false,
    [ENABLE_MERCHANDISE_BRANCH_VIEW]: false,
    [MKT_SCHEDULE_REFRESH] : ['10:00', '12:00', '16:00'], //in military time
    [ENABLE_MERCHANDISE_BUSINESS_UNIT_VIEW]: false

}